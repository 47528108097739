import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDSCH85l5iPdv7Kawrx3IPy4Oz8tfIBYYk",
    authDomain: "brixlet.firebaseapp.com",
    projectId: "brixlet",
    storageBucket: "brixlet.appspot.com",
    messagingSenderId: "358586651997",
    appId: "1:358586651997:web:3772d19e162337759b44b4",
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
