import { Typography } from "@mui/material";
import UiLink from "@mui/material/Link";

export default function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <UiLink color="inherit" href="https://mui.com/">
                Brixlet.com
            </UiLink>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}
