import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    User,
} from "@firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { Auth } from "../types/auth/Auth";
import { SignInProps } from "../types/auth/SignIn";
import { auth } from "./firebase/ClientApp";

const authContext = createContext<Auth>({} as Auth);

type ProvideAuthProps = {
    children: React.ReactNode;
};

export function ProvideAuth({ children }: ProvideAuthProps) {
    const auth: Auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}> {children} </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth(): Auth {
    const [user, setUser] = useState<User | null>(null);

    const signin = async ({ email, password }: SignInProps) => {
        const result = await signInWithEmailAndPassword(auth, email, password);
        if (result.user) {
            setUser(result.user);
        }
        return result;
    };

    const signup = async ({ email, password }: SignInProps) => {
        return setUser(
            await (
                await createUserWithEmailAndPassword(auth, email, password)
            ).user
        );
    };

    useEffect(() => {
        const unsubscirbe = onAuthStateChanged(auth, (a) => {
            if (a) {
                setUser(a);
            } else {
                setUser(null);
            }
        });

        return () => unsubscirbe();
    });

    return {
        user,
        signin,
        signup,
    };
}
