import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAuth } from "../utils/auth";
import AuthLayout from "../components/Auth/Layout";

export default function Register() {
    const auth = useAuth();

    const handleSubmit = (data: FormData) => {
        const email = data.get("email")?.toString();
        const password = data.get("password")?.toString();

        if (email && password) {
            auth.signup({
                email,
                password,
            });
        }
    };

    return (
        <AuthLayout title="Create an account" onClick={handleSubmit}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Sign Up
            </Button>
            <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                    <Link to="/login">{"You have an account? Sign In"}</Link>
                </Grid>
            </Grid>
        </AuthLayout>
    );
}
