import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/common/Layout";
import Login from "./pages/Login";
import { ProvideAuth } from "./utils/auth";
import Register from "./pages/Register";
import PrivateRoute from "./components/Router/PrivateRoute";

export default function App() {
    return (
        <Router>
            <ProvideAuth>
                <Layout>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <Home />
                                </PrivateRoute>
                            }
                        />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                    </Routes>
                </Layout>
            </ProvideAuth>
        </Router>
    );
}
