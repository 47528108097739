import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useAuth } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../components/Auth/Layout";
import { useState } from "react";

export default function Login() {
    const auth = useAuth();
    const navigate = useNavigate();

    const [error, setError] = useState("");

    const handleSubmit = (data: FormData) => {
        const email = data.get("email")?.toString();
        const password = data.get("password")?.toString();

        if (email && password) {
            auth.signin({
                email,
                password,
            })
                .then(() => {
                    navigate("/");
                })
                .catch((error) => setError(error.message));
        }
    };

    return (
        <AuthLayout title="Sign In" onClick={handleSubmit}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={!!error}
                helperText={error || undefined}
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Sign In
            </Button>
            <Grid container>
                <Grid item xs>
                    <Link to={"#"}>Forgot password?</Link>
                </Grid>
                <Grid item>
                    <Link to="/register">
                        {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
            </Grid>
        </AuthLayout>
    );
}
